import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=318c60ea&scoped=true"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=318c60ea&prod&scoped=true&lang=css"
import style1 from "./dashboard.vue?vue&type=style&index=1&id=318c60ea&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318c60ea",
  null
  
)

export default component.exports