<template>
  <CChartDoughnut
    :datasets="defaultDatasets"
    :labels="sumber_dana"
    :options="computedOptions"
  />
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'

export default {
  name: 'SumberDanaChart',
  components: { CChartDoughnut },
  data() {
    return {
      sumber_dana: [],
      total: [],
      colors: []
    }
  },
  props: {
    tahun: {
      type: String,
      default: () => 2023
    },
  },

  methods: {
    // generate random color
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
  },
  mounted() {
      this.$store.dispatch("paket_pekerjaan/persentase_sumber_dana", { tahun: this.tahun }).then((resp) => {

        let grand_total = 0
        resp.data.forEach(element => {
            grand_total += element.total;
        });

        resp.data.forEach(element => {
          this.sumber_dana.push(element.sumber_dana)
          this.total.push((element.total))
          this.colors.push(this.getRandomColor())
        });
      }).catch(e => {
        this.$toast.error(e);
      });
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    },
    defaultOptions () {
      return {
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              // var label = data.labels[tooltipItem.index];
              // var val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return 'Rp.' + (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
          }
        },
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  }

}
</script>
